module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nick Heal","short_name":"Nick Heal","start_url":"/","background_color":"#e8f0fe","theme_color":"#e8f0fe","display":"standalone","icon":"static/favicon.png","icon_options":{"purpose":"any maskable"},"crossOrigin":"use-credentials","shortcuts":[{"name":"Open blog","short_name":"Blog","description":"See all my blog posts","url":"/","icons":[{"src":"static/favicon.png"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"25a03ffd774dbcf36fc1c63d4eefebef"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-174185911-1"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
