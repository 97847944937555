import React, { useState } from 'react'
import { ThemeProvider } from 'react-jss'
import GlobalStyles from './src/components/GlobalStyles'
import { Theme, ThemeSwitchContext } from './src/contexts/ThemeSwitch'
import themeLight from './src/theme/light'
import themeDark from './src/theme/dark'

function Wrapper({ children }) {
  const [theme, setTheme] = useState(Theme.Light)

  const themeValue = {
    currentTheme: theme,
    switchTheme: setTheme,
  }

  return (
    <ThemeSwitchContext.Provider value={themeValue}>
      <ThemeProvider theme={theme === Theme.Light ? themeLight : themeDark}>
        <GlobalStyles>{children}</GlobalStyles>
      </ThemeProvider>
    </ThemeSwitchContext.Provider>
  )
}

export const wrapPageElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>
}

// remove the JSS style tag generated on the server to avoid conflicts with the one added on the client
export const onInitialClientRender = () => {
  const ssStyles = window.document.getElementById(`server-side-jss`)
  if (ssStyles) {
    ssStyles.parentNode.removeChild(ssStyles)
  }
}
