import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors }) => ({
  '@global': {
    body: {
      background: `linear-gradient(135deg, ${colors.primary}, ${colors.primaryDark})`,
      fontFamily: 'Helvetica, Arial, sans-serif',
      margin: 0,
      minHeight: '100vh',
      padding: 0,
      transition: 'background 300ms ease-in-out',
    },
    '*': {
      boxSizing: 'border-box',
    },
  },
}))

export default function GlobalStyles(props: any) {
  useStyles()

  return <>{props.children}</>
}
