import { Theme } from '../type'
import colors from './colors'
import mq from '../defaults/mq'
import shadows from './shadows'

const theme: Theme = {
  colors,
  mq,
  shadows,
}

export default theme
