import { createContext, useContext } from 'react'

export enum Theme {
  Light,
  Dark,
}

type ThemeSwitch = {
  currentTheme: Theme
  switchTheme: (newTheme: Theme) => void
}

export const ThemeSwitchContext = createContext<ThemeSwitch>({
  currentTheme: Theme.Light,
  switchTheme: () => {},
})

export const useThemeSwitch = () => useContext(ThemeSwitchContext)
